/* eslint-disable */
export default {
  'routes': [
    {
      'resolve': () => import('./../../routes/404/index.js'),
      'rotation': 'routes',
      'path': '/404/',
      'dynamic': false,
      'title': 'Page Not Found - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': '404'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/accessibility-statement/index.js'),
      'rotation': 'routes',
      'path': '/accessibility/',
      'dynamic': false,
      'title': 'Accessibility Statement - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': '404'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/device/index.js'),
      'rotation': 'routes',
      'path': '/device/',
      'dynamic': false,
      'title': 'Device - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'device'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/download/index.js'),
      'rotation': 'routes',
      'path': '/download/',
      'dynamic': false,
      'title': 'Download App - Adventure Academy',
      'meta': {
        'requiresAuthentication': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'download-page'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/email-preferences/index.js'),
      'rotation': 'routes',
      'path': '/email-preferences/:recepient_id/',
      'dynamic': true,
      'title': 'Email Preferences - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'email-preferences'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/expired-emlink/index.js'),
      'rotation': 'routes',
      'path': '/expired-emlink/',
      'dynamic': false,
      'title': 'Expired Link - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'expired-emlink'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/extend-account/index.js'),
      'rotation': 'routes',
      'path': '/extend-account/',
      'dynamic': false,
      'title': 'Extend Account - Adventure Academy',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'extend-account'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/forgot-password/index.js'),
      'rotation': 'routes',
      'path': '/forgot-password/',
      'dynamic': false,
      'title': 'Forgot Password - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'forgot-password'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/home/index.js'),
      'rotation': 'routes',
      'path': '/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'customHTMLClasses': [
          'is-nmhp',
          'is-nmhp-blue-bg'
        ],
        'blockedForMembers': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'home'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/library-home-check-out/index.js'),
      'rotation': 'routes',
      'path': '/home-check-out/',
      'dynamic': false,
      'title': 'Home Check-Out - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'library-home-check-out-page'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/library-home-check-out-create-account/index.js'),
      'rotation': 'routes',
      'path': '/home-check-out/create-account/',
      'dynamic': false,
      'title': 'Home Check-Out - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'library-home-check-out-create-account-page'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/login/index.js'),
      'rotation': 'routes',
      'path': '/login/',
      'dynamic': false,
      'title': 'Log In - Adventure Academy',
      'meta': {
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'login'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/offer-expired/index.js'),
      'rotation': 'routes',
      'path': '/offer-expired/',
      'dynamic': false,
      'title': 'Offer Expired - Adventure Academy',
      'meta': {
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'offer-expired-page'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/paypal/index.js'),
      'rotation': 'routes',
      'path': '/paypal/',
      'dynamic': false,
      'title': 'PayPal - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'paypal'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/privacy-policy/index.js'),
      'rotation': 'routes',
      'path': '/privacy-policy/',
      'dynamic': false,
      'title': 'Privacy Policy - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/privacy-policy-20190418/index.js'),
      'rotation': 'routes',
      'path': '/privacy-policy/20190428/',
      'dynamic': false,
      'title': 'Privacy Policy - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy-policy-20190428'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/privacy-policy-previous/index.js'),
      'rotation': 'routes',
      'path': '/privacy-policy/previous/',
      'dynamic': false,
      'title': 'Privacy Policy - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy-previous'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/reactivation/index.js'),
      'rotation': 'routes',
      'path': '/reactivation/',
      'dynamic': false,
      'title': 'Reactivate Account - Adventure Academy',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'reactivation-page'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/readingiq-offer/index.js'),
      'rotation': 'routes',
      'path': '/readingiq-offer/',
      'dynamic': false,
      'title': 'ReadingIQ Offer - Adventure Academy',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'readingiq-offer'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/redeem/index.js'),
      'rotation': 'routes',
      'path': '/redeem/',
      'dynamic': false,
      'title': 'Redeem Code - Adventure Academy',
      'meta': {
        'customHTMLClasses': [
          'is-nmhp',
          'is-nmhp-blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'redeem-page'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/redeem-create-account/index.js'),
      'rotation': 'routes',
      'path': '/redeem/new/create-account/',
      'dynamic': true,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'redeem-create-account'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/register-app/index.js'),
      'rotation': 'routes',
      'path': '/register-app/',
      'dynamic': false,
      'title': 'Register App - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'register-app'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/register-ios/index.js'),
      'rotation': 'routes',
      'path': '/register-ios/',
      'dynamic': false,
      'title': 'Register Ios - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'register-ios'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription/index.js'),
      'rotation': 'routes',
      'path': '/subscription/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'account_create'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/reset-password/index.js'),
      'rotation': 'routes',
      'path': '/reset-password/:resetPasswordToken/',
      'dynamic': true,
      'title': 'Password Reset - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'reset-password'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-annual/index.js'),
      'rotation': 'routes',
      'path': '/subscription-annual/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-annual'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/survey/index.js'),
      'rotation': 'routes',
      'path': '/survey/',
      'dynamic': false,
      'title': 'Survey - Adventure Academy',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'survey'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/terminate/index.js'),
      'rotation': 'routes',
      'path': '/terminate/cFn0vG4yW/',
      'dynamic': false,
      'title': 'Terminate - Adventure Academy',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'terminate'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/terms-and-conditions/index.js'),
      'rotation': 'routes',
      'path': '/terms-and-conditions/',
      'dynamic': false,
      'title': 'Terms and Conditions - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'tandc'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/upgrade/index.js'),
      'rotation': 'routes',
      'path': '/upgrade/',
      'dynamic': false,
      'title': 'Upgrade Account - Adventure Academy',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'upgrade'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/upgrade-standalone/index.js'),
      'rotation': 'routes',
      'path': '/upgrade/sta/',
      'dynamic': false,
      'title': 'Upgrade Account - Adventure Academy',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'upgrade-standalone'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/wordplay/index.js'),
      'rotation': 'routes',
      'path': '/wordplay/',
      'dynamic': false,
      'title': 'Adventure Academy - WordPlay',
      'meta': {
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'wordplay-page'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/curriculums/mathematics/index.js'),
      'rotation': 'routes',
      'path': '/learn-math/',
      'dynamic': false,
      'title': 'Adventure Academy - Mathematics',
      'meta': {
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'mathematics-page'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/giveaway/back-to-school-sweepstakes/index.js'),
      'rotation': 'routes',
      'path': '/tandc/back-to-school-2021/',
      'dynamic': false,
      'title': 'Giveaway - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'tandc'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/giveaway/spring-sweepstakes/index.js'),
      'rotation': 'routes',
      'path': '/tandc/spring-sweepstakes-2021/',
      'dynamic': false,
      'title': 'Giveaway - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'tandc'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/giveaway/summer-sweepstakes/index.js'),
      'rotation': 'routes',
      'path': '/tandc/summer-sweepstakes-2021/',
      'dynamic': false,
      'title': 'Giveaway - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'tandc'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/outbound-redirects/customer-support/index.js'),
      'rotation': 'routes',
      'path': '/customer-support/',
      'dynamic': false,
      'title': 'Customer Support - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'customer-support'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/outbound-redirects/support/index.js'),
      'rotation': 'routes',
      'path': '/support/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'support'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/outbound-redirects/support-forgot-email/index.js'),
      'rotation': 'routes',
      'path': '/support-forgot-email/',
      'dynamic': false,
      'title': 'Customer Support - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'support-forgot-email'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/outbound-redirects/support-forgot-password/index.js'),
      'rotation': 'routes',
      'path': '/support-forgot-password/',
      'dynamic': false,
      'title': 'Customer Support - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'support-forgot-password'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/back-to-school-5for2-6x1tr68/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/6x1tr68/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-6x1tr68'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/evergreen-10for3-9e4952/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/9e4952/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1666718640,
              'endTime': 1667285999
            }
          ],
          'redirectURL': '/'
        }
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/evergreen-1for1-25e5b67/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/25e5b67/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-25e5b67'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/evergreen-29-7294b9b/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/7294b9b/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-7294b9b'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/evergreen-29-988abbc/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/988abbc/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-988abbc'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/evergreen-2MF-1bb8ef3/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/1bb8ef3/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'requiresValidRedeemCode': false,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-1bb8ef3'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/evergreen-45-c09c263/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/c09c263/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-c09c263'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/evergreen-45plusRIQ-4a1u64a/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/4a1u64a/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1724698800,
              'endTime': 1726383540
            }
          ],
          'redirectURL': '/'
        },
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-4a1u64a'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/evergreen-45plusRIQ-confirm/index.js'),
      'rotation': 'routes',
      'path': '/riq-offer-confirm/',
      'dynamic': false,
      'title': 'ReadingIQ Offer Confirm - Adventure Academy',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'riq-offer-confirm'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/evergreen-999for3-6b2db43/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/6b2db43/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-6b2db43'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/google-45-537e375/index.js'),
      'rotation': 'routes',
      'path': '/subscription_537e375/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': false,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-537e375'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/groupon-2MF-gp5r1n4/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/gp5r1n4/:redeemCode/',
      'dynamic': true,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'requiresValidRedeemCode': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-gp5r1n4'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/holiday-july-fourth-45-7cm8kpm/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/7cm8kpm/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-7cm8kpm'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/holiday-presidents-day-45-knv3g45/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/knv3g45/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-knv3g45'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/marvel-5for2-4w0sq47/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/4w0sq47/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1712818740,
              'endTime': 1714978740
            }
          ],
          'redirectURL': '/'
        }
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/memorial-day-45-3df3t4r/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/3df3t4r/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-3df3t4r'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/seasonal-fall-45-f654e6b/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/f654e6b/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-f654e6b'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/seasonal-fall-5for2-5012c22/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/5012c22/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': false,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1726815600,
              'endTime': 1729407599
            }
          ],
          'redirectURL': '/'
        },
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-5012c22'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/seasonal-spring-45-5m73xa0/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/5m73xa0/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-5m73xa0'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/seasonal-summer-45-8ab6c33/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/8ab6c33/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-8ab6c33'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/seasonal-summer-999for3-6b1628/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/6b1628/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'requiresValidRedeemCode': false,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-seasonal-offer-6b1628'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/seasonal-winter-5for2-3f0dc67/index.js'),
      'rotation': 'routes',
      'path': '/subscription/seasonal/offer/3f0dc67/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-seasonal-offer-3f0dc67'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/subscription-20-for-4-c908dc6/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/c908dc6/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-c908dc6'
          }
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1697180340,
              'endTime': 1699167540
            }
          ],
          'redirectURL': '/'
        }
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/subscription-6-for-6-4r5t6ya/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/4r5t6ya/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1712559540,
              'endTime': 1714892340
            }
          ],
          'redirectURL': '/'
        }
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offer/summer-5for2-5w0sq57/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/5w0sq57/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-5w0sq57'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/benfranklin/index.js'),
      'rotation': 'routes',
      'path': '/benfranklin/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'ben-franklin'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/comic/index.js'),
      'rotation': 'routes',
      'path': '/comic/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'comic'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/comics/index.js'),
      'rotation': 'routes',
      'path': '/comics/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'comics'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/coupon/index.js'),
      'rotation': 'routes',
      'path': '/coupon/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'coupon'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/earth/index.js'),
      'rotation': 'routes',
      'path': '/earth/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'earth'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/finn/index.js'),
      'rotation': 'routes',
      'path': '/finn/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'finn'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/fluent5000/index.js'),
      'rotation': 'routes',
      'path': '/fluent5000/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'fluent5000'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/groupon/index.js'),
      'rotation': 'routes',
      'path': '/groupon/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'groupon'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/mars/index.js'),
      'rotation': 'routes',
      'path': '/mars/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'mars'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/mayan/index.js'),
      'rotation': 'routes',
      'path': '/mayan/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'mayan'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/nature/index.js'),
      'rotation': 'routes',
      'path': '/nature/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'nature'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/pants/index.js'),
      'rotation': 'routes',
      'path': '/pants/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'pants'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/parenting/index.js'),
      'rotation': 'routes',
      'path': '/parenting/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'parenting'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/parents/index.js'),
      'rotation': 'routes',
      'path': '/parents/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'parents'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/six/index.js'),
      'rotation': 'routes',
      'path': '/six/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'six'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/vanity/tumble/index.js'),
      'rotation': 'routes',
      'path': '/tumble/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'locale': 'en-US',
      'template': 'main'
    }
  ],
  '1740-45-riq-two-step': [
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/back-to-school-5for2-6x1tr68/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/6x1tr68/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-6x1tr68'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/evergreen-10for3-9e4952/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/9e4952/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1666718640,
              'endTime': 1667285999
            }
          ],
          'redirectURL': '/'
        }
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/evergreen-1for1-25e5b67/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/25e5b67/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-25e5b67'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/evergreen-29-7294b9b/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/7294b9b/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-7294b9b'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/evergreen-29-988abbc/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/988abbc/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-988abbc'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/evergreen-2MF-1bb8ef3/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/1bb8ef3/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'requiresValidRedeemCode': false,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-1bb8ef3'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/evergreen-45-c09c263/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/c09c263/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-c09c263'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/evergreen-45plusRIQ-4a1u64a/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/4a1u64a/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1724698800,
              'endTime': 1726383540
            }
          ],
          'redirectURL': '/'
        },
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-4a1u64a'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/evergreen-45plusRIQ-confirm/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/riq-offer-confirm/',
      'dynamic': false,
      'title': 'ReadingIQ Offer Confirm - Adventure Academy',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'riq-offer-confirm'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/evergreen-999for3-6b2db43/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/6b2db43/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-6b2db43'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/google-45-537e375/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription_537e375/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': false,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-537e375'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/groupon-2MF-gp5r1n4/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/gp5r1n4/:redeemCode/',
      'dynamic': true,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'requiresValidRedeemCode': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-gp5r1n4'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/holiday-july-fourth-45-7cm8kpm/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/7cm8kpm/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-7cm8kpm'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/holiday-presidents-day-45-knv3g45/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/knv3g45/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-knv3g45'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/marvel-5for2-4w0sq47/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/4w0sq47/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1712818740,
              'endTime': 1714978740
            }
          ],
          'redirectURL': '/'
        }
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/memorial-day-45-3df3t4r/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/3df3t4r/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-3df3t4r'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/seasonal-fall-45-f654e6b/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/f654e6b/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-f654e6b'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/seasonal-fall-5for2-5012c22/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/5012c22/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': false,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1726815600,
              'endTime': 1729407599
            }
          ],
          'redirectURL': '/'
        },
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-5012c22'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/seasonal-spring-45-5m73xa0/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/5m73xa0/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-5m73xa0'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/seasonal-summer-45-8ab6c33/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/8ab6c33/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-8ab6c33'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/seasonal-summer-999for3-6b1628/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/6b1628/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'requiresValidRedeemCode': false,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-seasonal-offer-6b1628'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/seasonal-winter-5for2-3f0dc67/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/seasonal/offer/3f0dc67/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-seasonal-offer-3f0dc67'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/subscription-20-for-4-c908dc6/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/c908dc6/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-c908dc6'
          }
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1697180340,
              'endTime': 1699167540
            }
          ],
          'redirectURL': '/'
        }
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/subscription-6-for-6-4r5t6ya/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/4r5t6ya/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'expiration': {
          'activeRanges': [
            {
              'startTime': 1712559540,
              'endTime': 1714892340
            }
          ],
          'redirectURL': '/'
        }
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-1740-45-riq-two-step/subscription-offer/summer-5for2-5w0sq57/index.js'),
      'rotation': '1740-45-riq-two-step',
      'path': '/subscription/offer/5w0sq57/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-5w0sq57'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    }
  ],
  'google-play-test': [
    {
      'resolve': () => import('./../../routes-google-play-test/home/index.js'),
      'rotation': 'google-play-test',
      'path': '/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'customHTMLClasses': [
          'is-nmhp',
          'is-nmhp-blue-bg'
        ],
        'blockedForMembers': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'home'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-google-play-test/login/index.js'),
      'rotation': 'google-play-test',
      'path': '/login/',
      'dynamic': false,
      'title': 'Log In - Adventure Academy',
      'meta': {
        'customHTMLClasses': [
          'is-new-layout',
          'blue-bg'
        ],
        'pageInfo': [
          {
            'name': 'page',
            'id': 'login'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    }
  ],
  'ios-iap-test': [
    {
      'resolve': () => import('./../../routes-ios-iap-test/home/index.js'),
      'rotation': 'ios-iap-test',
      'path': '/',
      'dynamic': false,
      'title': 'Adventure Academy',
      'meta': {
        'customHTMLClasses': [
          'is-nmhp',
          'is-nmhp-blue-bg'
        ],
        'blockedForMembers': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'home'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-ios-iap-test/register-ios/index.js'),
      'rotation': 'ios-iap-test',
      'path': '/register-ios/',
      'dynamic': false,
      'title': 'Register Ios - Adventure Academy',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'register-ios'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-ios-iap-test/subscription/index.js'),
      'rotation': 'ios-iap-test',
      'path': '/subscription/',
      'dynamic': false,
      'title': 'Sign Up - Adventure Academy',
      'meta': {
        'blockedForMembers': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'account_create'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    }
  ]
};